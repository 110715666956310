/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Step13Data, StepOption, Steps } from '../../../model/Step';
import { RadioGroup, Select, Input } from '../../common/Form';
import { StepOptionRadioItem } from '../StepPage';

interface Props {
  stepData?: Step13Data;
  updateProject: (value) => void;
  displayCompact?: boolean;
  userIsProjectOwner?: boolean;
  additionalInfo?: JSX.Element;
}

const SpaceAndWaterHeatingPage: React.FC<Props> = ({
  stepData,
  updateProject,
  displayCompact,
  userIsProjectOwner,
  additionalInfo,
}) => {
  const { t } = useTranslation();

  const stepId = Steps.STEP_13;
  const fieldValue = `${stepId}-value`;

  const submitField = (value: { [key: string]: string | number }) => {
    let obj = value;
    if (
      (obj.hasOwnProperty('heatingTechDescription') ||
        obj.hasOwnProperty('ratedSpaceOutput') ||
        obj.hasOwnProperty('zoningDampersRequired') ||
        obj.hasOwnProperty('ratedDhwCapacity')) &&
      stepData &&
      stepData[fieldValue] === 'C'
    ) {
      obj = {
        ...stepData,
        ...value,
        [fieldValue]: 'C',
      };
    }
    updateProject({ [`steps.${[stepId]}`]: obj });
  };

  const { control, formState, getValues, setValue } = useForm<Step13Data>({
    defaultValues: {
      [fieldValue]: (stepData && stepData[fieldValue]) || 'A',
      heatingTechDescription: stepData?.heatingTechDescription || '',
      ratedSpaceOutput: stepData?.ratedSpaceOutput || '',
      zoningDampersRequired: stepData?.zoningDampersRequired || '',
      ratedDhwCapacity: stepData?.ratedDhwCapacity || '',
    },
    mode: 'onChange',
  });

  const formValues = getValues();

  const i18nForm = `step-${stepId}:form`;

  const getCompactReadonlyField = (fieldName: string): JSX.Element => {
    return (
      <div className="read-only-field">
        <label>{t(`${i18nForm}.c.form.${fieldName}`)}</label>
        <p>{formValues[fieldName]}</p>
      </div>
    );
  };

  const otherHeatingTechFields = formValues[fieldValue] === 'C' && (
    <>
      <Input
        id="heatingTechDescription"
        control={control}
        label={t(`${i18nForm}.c.form.heatingTechDescription`)}
        inputProps={{ disabled: !userIsProjectOwner }}
        onBlur={submitField}
        error={formState.errors.heatingTechDescription}
        validationRules={{
          maxLength: {
            value: 40,
            message: t('errors.common.maxLength', { maxLength: 40 }),
          },
        }}
      />
      <Input
        id="ratedSpaceOutput"
        control={control}
        label={t(`${i18nForm}.c.form.ratedSpaceOutput`)}
        inputProps={{ disabled: !userIsProjectOwner }}
        onBlur={submitField}
        type="number"
        error={formState.errors.ratedSpaceOutput}
        validationRules={{
          min: {
            value: 0,
            message: t('errors.project.minNumber', {
              min: 0,
            }).toString(),
          },
        }}
      />
      <Input
        id="zoningDampersRequired"
        control={control}
        label={t(`${i18nForm}.c.form.zoningDampersRequired`)}
        inputProps={{ disabled: !userIsProjectOwner }}
        onBlur={submitField}
        type="number"
        error={formState.errors.zoningDampersRequired}
        validationRules={{
          min: {
            value: 0,
            message: t('errors.project.minNumber', {
              min: 0,
            }).toString(),
          },
        }}
      />
      <Input
        id="ratedDhwCapacity"
        control={control}
        label={t(`${i18nForm}.c.form.ratedDhwCapacity`)}
        inputProps={{ disabled: !userIsProjectOwner }}
        onBlur={submitField}
        type="number"
        error={formState.errors.ratedDhwCapacity}
        validationRules={{
          min: {
            value: 0,
            message: t('errors.project.minNumber', {
              min: 0,
            }).toString(),
          },
        }}
      />
    </>
  );

  const options: {
    label: string;
    value: string | number;
    disabled?: boolean;
    labelData: StepOption;
  }[] = [
    {
      label: `${t(`${i18nForm}.a.option.title`)} - ${t(
        `${i18nForm}.a.option.text`
      )}`,
      value: 'A',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:13A.alt'),
          fileName: t('figures:13A.fileName'),
          longDescription: t('figures:13A.longDesc'),
        },
        modal: {
          content: (
            <ul>
              <li>{t(`${i18nForm}.a.modal.text1`)}</li>
              <li>{t(`${i18nForm}.a.modal.text2`)}</li>
            </ul>
          ),
          title: t(`${i18nForm}.a.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.a.option.text`),
          title: t(`${i18nForm}.a.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.b.option.title`)} - ${t(
        `${i18nForm}.b.option.text`
      )}`,
      value: 'B',
      disabled: !userIsProjectOwner,
      labelData: {
        img: {
          alt: t('figures:13B.alt'),
          fileName: t('figures:13B.fileName'),
          longDescription: t('figures:13B.longDesc'),
        },
        modal: {
          content: (
            <ul>
              <li>{t(`${i18nForm}.b.modal.text1`)}</li>
              <li>{t(`${i18nForm}.b.modal.text2`)}</li>
              <li>{t(`${i18nForm}.b.modal.text3`)}</li>
            </ul>
          ),
          title: t(`${i18nForm}.b.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.b.option.text`),
          title: t(`${i18nForm}.b.option.title`),
        },
      },
    },
    {
      label: `${t(`${i18nForm}.c.option.title`)} - ${t(
        `${i18nForm}.c.option.text`
      )}`,
      value: 'C',
      disabled: !userIsProjectOwner,
      labelData: {
        modal: {
          content: (
            <>
              <p>{t(`${i18nForm}.c.modal.text1`)}</p>
            </>
          ),
          title: t(`${i18nForm}.c.modal.title`),
        },
        option: {
          text: t(`${i18nForm}.c.option.text`),
          title: t(`${i18nForm}.c.option.title`),
        },
        appendElements: [otherHeatingTechFields],
      },
    },
  ];

  const [selectedOption, setSelectedOption] = useState(
    options.find((o) => o.value === formValues[fieldValue])
  );

  useEffect(() => {
    if (!(stepData && stepData[fieldValue])) {
      submitField(formValues as any);
    }
  }, [formValues[fieldValue]]);

  useEffect(() => {
    setSelectedOption(options.find((o) => o.value === stepData?.[fieldValue]));
    setValue('spaceWaterHeating-value', stepData?.[fieldValue] || 'A');
    setValue('heatingTechDescription', stepData?.heatingTechDescription || '');
    setValue('ratedSpaceOutput', stepData?.ratedSpaceOutput || '');
    setValue('zoningDampersRequired', stepData?.zoningDampersRequired || '');
    setValue('ratedDhwCapacity', stepData?.ratedDhwCapacity || '');
  }, [stepData]);

  return (
    <>
      <form noValidate>
        {displayCompact ? (
          <Row>
            <Col xs={8} md={8}>
              {userIsProjectOwner ? (
                <>
                  <Select
                    label={t('checklist.selectedOption')}
                    id={fieldValue}
                    control={control}
                    options={options}
                    formGroupClasses={'step-option-radio'}
                    onChange={submitField}
                  />
                  {otherHeatingTechFields}
                </>
              ) : (
                <>
                  <div className="selected-option-text">
                    <label>{selectedOption?.labelData.option?.title}</label>
                    <p>{selectedOption?.labelData.option?.text}</p>
                  </div>
                  {getCompactReadonlyField('heatingTechDescription')}
                  {getCompactReadonlyField('ratedSpaceOutput')}
                  {getCompactReadonlyField('zoningDampersRequired')}
                  {getCompactReadonlyField('ratedDhwCapacity')}
                </>
              )}
              {additionalInfo}
            </Col>
            <Col xs={4} md={4}>
              {selectedOption && selectedOption.value !== 'C' && (
                <img
                  src={`/assets/images/${selectedOption.labelData.img?.fileName}`}
                  alt={selectedOption.labelData.img?.alt}
                  className={'compact-image'}
                />
              )}
            </Col>
          </Row>
        ) : (
          <>
            <p>{t('step-spaceWaterHeating:description')}</p>
            <RadioGroup
              id={fieldValue}
              control={control}
              items={options}
              radioClassName="step-option-radio"
              onChange={submitField}
              renderLabel={(item) => <StepOptionRadioItem item={item} />}
            />
          </>
        )}
      </form>
    </>
  );
};

export default SpaceAndWaterHeatingPage;
