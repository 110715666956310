/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useAccount } from '@azure/msal-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UserRoles } from '../../model/User';
import { Input, Select, ServerFeedback } from '../common/Form';
import { Collaborator, Project } from '../../model/Project';
import { AppRoute } from '../../const';
import { LocalizedNavLink } from '../i18n';
import { CollaboratorTable } from '../CreateProject';
import { saveNewProject } from '../../api';
import { partialUpdateProject } from '../../api/projects';
import { JsonPatchOperation } from '../../model/JsonPatchOperation';

type FormValues = {
  name: string;
  homeBuilder: string;
  ownerRole: UserRoles;
  collaborators: Collaborator[];
};

interface Props {
  createCallback?: (data) => void;
  project?: Project;
  updateCallback?: () => void;
  userProjects: Project[];
}

const ProjectSettingsForm: React.FC<Props> = ({
  createCallback,
  project,
  updateCallback,
  userProjects,
}) => {
  const { t } = useTranslation();
  const account = useAccount();
  const [serverError, setServerError] = useState<string | undefined>();
  const [successMsg, setSuccessMsg] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const onMutationError = (err) => {
    setServerError(t('errors.common.unknown'));
    console.log(err);
  };

  const saveNewMutation = useMutation({
    mutationFn: (project: any) => saveNewProject(project),
  });

  const partialUpdateMutation = useMutation({
    mutationFn: (changes: JsonPatchOperation[]) =>
      partialUpdateProject(project?.id ?? '', changes),
  });

  const { control, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: {
      name: project?.name || '',
      homeBuilder: project?.checklist?.homeBuilder || '',
      ownerRole: project?.ownerRole || UserRoles.HVAC_DESIGNER,
      collaborators: project?.collaborators || [],
    },
    mode: 'onChange',
    shouldUnregister: true,
  });

  const roles = [
    {
      label: t(`user.role.${UserRoles.HVAC_DESIGNER}`),
      value: UserRoles.HVAC_DESIGNER,
    },
    {
      label: t(`user.role.${UserRoles.HVAC_CONTRACTOR}`),
      value: UserRoles.HVAC_CONTRACTOR,
    },
    {
      label: t(`user.role.${UserRoles.ARCHITECT}`),
      value: UserRoles.ARCHITECT,
    },
    {
      label: t(`user.role.${UserRoles.HOME_BUILDER}`),
      value: UserRoles.HOME_BUILDER,
    },
    {
      label: t(`user.role.${UserRoles.HOME_OWNER}`),
      value: UserRoles.HOME_OWNER,
    },
  ];

  const nameMap = useMemo(() => {
    const names = new Set();
    userProjects.forEach((project) => {
      names.add(project.name?.toLowerCase());
    });
    return names;
  }, [userProjects]);

  const onSubmit = (values) => {
    if (project) {
      partialUpdateMutation.mutate(
        [
          { op: 'add', path: '/updatedAt', value: new Date() },
          { op: 'add', path: '/ownerRole', value: values.ownerRole },
          { op: 'add', path: '/name', value: values.name },
          {
            op: 'add',
            path: '/collaborators',
            value: values.collaborators || [],
          },
          {
            op: 'add',
            path: '/checklist/homeBuilder',
            value: values.homeBuilder,
          },
        ],
        {
          onError: onMutationError,
          onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['projects'] });
            if (updateCallback) {
              updateCallback();
            } else {
              setSuccessMsg(t('common.saveChangeSuccess'));
            }
          },
        }
      );
    } else {
      saveNewMutation.mutate(
        {
          owner: account?.localAccountId,
          createdAt: new Date(),
          updatedAt: new Date(),
          completedSteps: [],
          isStarted: false,
          isCompleted: false,
          ownerRole: values.ownerRole,
          name: values.name,
          checklist: {
            homeBuilder: values.homeBuilder,
          },
          collaborators: values.collaborators || [],
        },
        {
          onError: onMutationError,
          onSuccess: async ({ data }) => {
            if (data && data.id) {
              await queryClient.invalidateQueries({ queryKey: ['projects'] });
              if (createCallback) {
                createCallback(data);
              }
            } else {
              setServerError(t('errors.common.requiresRecentLogin'));
            }
          },
        }
      );
    }
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => setSuccessMsg(null)}
      >
        <label className="section-label">
          {t('project.settings.name.label')}
        </label>
        <p>{t('project.settings.name.description')}</p>
        <Input
          id="name"
          className="create-project-name"
          control={control}
          error={formState.errors.name}
          label={t('project.settings.name.label')}
          labelHidden={true}
          validationRules={{
            maxLength: {
              value: 80,
              message: t('errors.common.maxLength', { maxLength: 80 }),
            },
            required: t('errors.common.required').toString(),
            validate: (value) => {
              if (
                project?.name !== value &&
                nameMap.has(String(value).toLowerCase())
              ) {
                return t('errors.project.nameAlreadyUsed').toString();
              }
              return true;
            },
          }}
        />

        <label className="section-label">
          {t('project.settings.homeBuilder.label')}
        </label>
        <p>{t('project.settings.homeBuilder.description')}</p>
        <Input
          id="homeBuilder"
          className="create-project-name"
          control={control}
          error={formState.errors.homeBuilder}
          label={t('project.settings.homeBuilder.label')}
          labelHidden={true}
          validationRules={{
            maxLength: {
              value: 40,
              message: t('errors.common.maxLength', { maxLength: 80 }),
            },
          }}
        />

        <label className="section-label">
          {t('project.settings.ownerRole.label')}
        </label>
        <p>{t('project.settings.ownerRole.description')}</p>
        <Select
          id="ownerRole"
          control={control}
          error={formState.errors.ownerRole}
          options={roles}
          label={t('project.settings.ownerRole.label')}
          labelHidden={true}
          validationRules={{
            required: t('errors.common.required').toString(),
          }}
        />

        <label className="section-label">
          {t('project.settings.collaborators.label')}
        </label>
        <p>{t('project.settings.collaborators.description')}</p>

        <CollaboratorTable
          control={control}
          error={formState.errors.collaborators}
        />

        <div className="form-actions">
          {!!serverError && (
            <ServerFeedback type="error">{t(serverError)}</ServerFeedback>
          )}
          <>
            <Button
              type="submit"
              disabled={
                saveNewMutation.isPending || partialUpdateMutation.isPending
              }
              aria-disabled={
                saveNewMutation.isPending || partialUpdateMutation.isPending
              }
              bsStyle="primary"
            >
              {project?.isStarted
                ? t('common.saveChanges')
                : t('common.saveContinue')}
            </Button>
            <LocalizedNavLink
              className="btn btn-default btn-sm"
              isActive={() => false}
              to={
                project?.isStarted
                  ? `${t(AppRoute.Project)}/${project.id}`
                  : AppRoute.Dashboard
              }
            >
              {t('common.cancel')}
            </LocalizedNavLink>
          </>

          {successMsg && (
            <ServerFeedback type="success">{successMsg}</ServerFeedback>
          )}
        </div>
      </Form>
    </>
  );
};

export default ProjectSettingsForm;
