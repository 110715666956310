import { Helmet } from 'react-helmet-async';
import { Col, PageHeader, Row, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { useUserIsProjectOwner } from '../../../hooks/useUserIsProjectOwner';
import { Project } from '../../../model/Project';
import { ProjectStatus, ProjectMenu } from '../';
import { Input, RadioGroup } from '../../common/Form';
import { ProjectComments } from '../Comments';
import { MD_MIN_SIZE, SM_MAX_SIZE } from '../../../const/Responsive';
import { usePartialProjectUpdateMutation } from '../../../hooks/usePartialProjectUpdateMutation';
import { ChecklistAccordian } from './';
import { PrintChecklistButton } from './';

interface Props {
  project: Project;
}

const ProjectChecklistPage: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();

  const checklistValues = project.checklist || null;
  const { control, formState } = useForm({
    defaultValues: {
      homeBuilder: (checklistValues && checklistValues.homeBuilder) || '',
      hvacDesigner: (checklistValues && checklistValues.hvacDesigner) || '',
      houseId: (checklistValues && checklistValues.houseId) || '',
      appliedTo: (checklistValues && checklistValues.appliedTo) || '',
    },
    mode: 'onChange',
  });
  const userIsProjectOwner = useUserIsProjectOwner(project);
  const partialUpdateProjectMutation = usePartialProjectUpdateMutation(project);

  const getAppliedToLabel = (appliedToValue) => {
    switch (appliedToValue) {
      case 'thisHome':
        return t('checklist.form.thisHome.label');
      case 'subdivision':
        return t('checklist.form.subdivision.label');
      default:
        return '';
    }
  };

  const submitField = (newValues) => {
    partialUpdateProjectMutation.mutate([
      { op: 'add', path: '/updatedAt', value: new Date() },
      {
        op: 'add',
        path: '/checklist',
        value: {
          ...checklistValues,
          ...newValues,
        },
      },
    ]);
  };
  return (
    <div className="checklist-page">
      <Helmet>
        <title>{t('pageTitle.overview', { projectName: project.name })}</title>
      </Helmet>
      <PageHeader>
        {t('pageTitle.checklist', { projectName: project.name })}
      </PageHeader>
      <ProjectMenu project={project} />
      <Row>
        <MediaQuery maxWidth={SM_MAX_SIZE}>
          <Col xs={12}>
            <ProjectStatus project={project} />
          </Col>
        </MediaQuery>

        <Col xs={12} md={8}>
          <h2>{t('checklist.title')}</h2>
          <p>{t('checklist.description')}</p>
          <Form>
            {userIsProjectOwner ? (
              <Input
                id="homeBuilder"
                control={control}
                label={t('checklist.form.homeBuilder.label')}
                onBlur={submitField}
                error={formState.errors.homeBuilder}
                validationRules={{
                  maxLength: {
                    value: 50,
                    message: t('errors.common.maxLength', { maxLength: 50 }),
                  },
                }}
              />
            ) : (
              <div>
                <label>{t('checklist.form.homeBuilder.label')}</label>
                <p>{checklistValues?.homeBuilder}</p>
              </div>
            )}
            {userIsProjectOwner ? (
              <Input
                id="hvacDesigner"
                control={control}
                label={t('checklist.form.hvacDesigner.label')}
                onBlur={submitField}
                error={formState.errors.hvacDesigner}
                validationRules={{
                  maxLength: {
                    value: 50,
                    message: t('errors.common.maxLength', { maxLength: 50 }),
                  },
                }}
              />
            ) : (
              <div>
                <label>{t('checklist.form.hvacDesigner.label')}</label>
                <p>{checklistValues?.hvacDesigner}</p>
              </div>
            )}
            {userIsProjectOwner ? (
              <Input
                id="houseId"
                control={control}
                label={t('checklist.form.houseId.label')}
                onBlur={submitField}
                error={formState.errors.houseId}
                validationRules={{
                  maxLength: {
                    value: 50,
                    message: t('errors.common.maxLength', { maxLength: 50 }),
                  },
                }}
              />
            ) : (
              <div>
                <label>{t('checklist.form.houseId.label')}</label>
                <p>{checklistValues?.houseId}</p>
              </div>
            )}
            {userIsProjectOwner ? (
              <RadioGroup
                id="appliedTo"
                control={control}
                label={t('checklist.form.appliedTo.label')}
                items={[
                  {
                    value: 'thisHome',
                    label: t('checklist.form.thisHome.label'),
                  },
                  {
                    value: 'subdivision',
                    label: t('checklist.form.subdivision.label'),
                  },
                ]}
                onChange={(value) => submitField(value)}
              />
            ) : (
              <div>
                <label>{t('checklist.form.appliedTo.label')}</label>
                <p>{getAppliedToLabel(checklistValues?.appliedTo)}</p>
              </div>
            )}
          </Form>
          <p>
            <em style={{ fontSize: '16px' }}>{t('checklist.footNote')}</em>
          </p>
          <PrintChecklistButton project={project} />
          <ChecklistAccordian project={project} />
        </Col>

        <Col xs={12} md={4}>
          <MediaQuery minWidth={MD_MIN_SIZE}>
            <ProjectStatus project={project} />
          </MediaQuery>
          <ProjectComments project={project} pageId="checklist" />
        </Col>
      </Row>
    </div>
  );
};

export default ProjectChecklistPage;
