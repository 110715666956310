import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { Pagination } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { PaginationChangePage } from 'react-data-table-component/dist/DataTable/types';
import { MD_MIN_SIZE, SM_MAX_SIZE } from '../../../const/Responsive';

interface Props {
  currentPage: number;
  onChangePage: PaginationChangePage;
  rowCount: number;
  rowsPerPage: number;
}

const DatatablePagination: React.FC<Props> = ({
  currentPage,
  onChangePage,
  rowsPerPage,
  rowCount,
}) => {
  const { t } = useTranslation();

  const isSM = useMediaQuery({ query: `(max-width: ${SM_MAX_SIZE}px)` });

  const numberOfPages = Math.ceil(rowCount / rowsPerPage);

  const pages = useMemo(() => {
    let pageArray: (number | 'ellipsis')[] = [];
    if (isSM) {
      if (numberOfPages <= 2) {
        for (let i = 1; i <= numberOfPages; i++) {
          pageArray.push(i);
        }
      } else {
        if (currentPage === 1) {
          pageArray = [1, 2, 'ellipsis'];
        } else if (currentPage === numberOfPages) {
          pageArray = ['ellipsis', numberOfPages - 1, numberOfPages];
        } else {
          pageArray = ['ellipsis', currentPage, 'ellipsis'];
        }
      }
    } else {
      if (numberOfPages <= 5) {
        for (let i = 1; i < numberOfPages + 1; i++) {
          pageArray.push(i);
        }
      } else {
        if (currentPage < 3) {
          pageArray = [1, 2, 3, 4, 5, 'ellipsis', numberOfPages];
        } else if (currentPage >= 3 && currentPage <= numberOfPages - 3) {
          pageArray = [
            1,
            'ellipsis',
            currentPage - 1,
            currentPage,
            currentPage + 1,
            'ellipsis',
            numberOfPages,
          ];
        } else if (currentPage >= numberOfPages - 3) {
          pageArray = [
            1,
            'ellipsis',
            numberOfPages - 4,
            numberOfPages - 3,
            numberOfPages - 2,
            numberOfPages - 1,
            numberOfPages,
          ];
        }
      }
    }
    return pageArray;
  }, [numberOfPages, currentPage, isSM]);

  return (
    <div className="pagination-container">
      <Pagination>
        <Pagination.Prev
          aria-disabled={currentPage === 1}
          disabled={currentPage === 1}
          onClick={() => onChangePage(currentPage - 1, rowCount)}
        >
          <MediaQuery maxWidth={SM_MAX_SIZE}>
            <FontAwesomeIcon icon={faChevronLeft} size="xs" />
          </MediaQuery>
          <MediaQuery minWidth={MD_MIN_SIZE}>
            {t('pagination.previous')}
          </MediaQuery>
        </Pagination.Prev>
        {pages.map((pageNumber, index) => {
          if (pageNumber === 'ellipsis') {
            return <Pagination.Ellipsis key={`pagination-ellipsis-${index}`} />;
          }
          return (
            <Pagination.Item
              key={`pagination-page-${pageNumber}`}
              active={currentPage === pageNumber}
              onClick={() => onChangePage(pageNumber, rowCount)}
            >
              {pageNumber}
            </Pagination.Item>
          );
        })}
        <>
          <Pagination.Next
            aria-disabled={currentPage === numberOfPages}
            disabled={currentPage === numberOfPages}
            onClick={() => onChangePage(currentPage + 1, rowCount)}
          >
            <MediaQuery maxWidth={SM_MAX_SIZE}>
              <FontAwesomeIcon icon={faChevronRight} size="xs" />
            </MediaQuery>
            <MediaQuery minWidth={MD_MIN_SIZE}>
              {t('pagination.next')}
            </MediaQuery>
          </Pagination.Next>
        </>
      </Pagination>
    </div>
  );
};

export default DatatablePagination;
