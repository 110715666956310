import { useMemo } from 'react';
import { Col, PageHeader, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import MediaQuery from 'react-responsive';
import { useUserIsProjectOwner } from '../../../hooks/useUserIsProjectOwner';
import { ProjectMenu, ProjectStatus } from '..';
import { AppRoute } from '../../../const';
import { StepsList } from '../../../const/Steps';
import { Project } from '../../../model/Project';
import ProjectDetailsPage from '../Steps/ProjectDetailsPage';
import {
  AlternativeReturnAirPage,
  AlternativeSupplyDuctsPage,
  AlternativeSupplyOutletsPage,
  AutomaticZoningEquipmentPage,
  DetermineHeatLostGainPage,
  DuctSealingPage,
  SizingCoolingCapacityPage,
  ZoningSupplyDuctsPage,
  DehumidificationStrategyPage,
  SizingOfHeatingEquipmentPage,
  StagingModulatingHeatingPage,
  SpaceAndWaterHeatingPage,
} from '../Steps';
import { Steps } from '../../../model/Step';
import { ProjectComments } from '../Comments';
import { MD_MIN_SIZE, SM_MAX_SIZE } from '../../../const/Responsive';
import { Disclaimer } from '../../common';
import { usePartialProjectUpdateMutation } from '../../../hooks/usePartialProjectUpdateMutation';
import { ProjectStepFooter, ProjectStepHeader } from '.';

interface Props {
  project: Project;
}

const ProjectStepsPage: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { stepId }: { stepId: string } = useParams();
  const steps = project.steps || {};

  const userIsProjectOwner = useUserIsProjectOwner(project);
  const partialUpdateProjectMutation = usePartialProjectUpdateMutation(project);

  const updateProjectFn = (value: {
    [key: string]: string | number | boolean;
  }) => {
    if (userIsProjectOwner) {
      partialUpdateProjectMutation.mutate([
        { op: 'add', path: '/updatedAt', value: new Date() },
        {
          op: 'add',
          path: `/${Object.keys(value)[0].replace('.', '/')}`,
          value: value[Object.keys(value)[0]],
        },
      ]);
    }
  };

  const step = useMemo(() => {
    const stepItem = StepsList.find((stepItem) => t(stepItem.path) === stepId);

    if (!stepItem) {
      history.replace(`/${t(AppRoute.NotFound)}`);
      return;
    }

    return stepItem;
  }, [stepId, history, t]);

  return (
    <div className="step-page">
      <Helmet>
        <title>
          {t('pageTitle.step', {
            projectName: project.name,
            stepNumber: step?.number,
          })}{' '}
          - {t('pageTitle.appName')}
        </title>
      </Helmet>
      <PageHeader>
        {t(`stepPage.pageTitle`, {
          projectName: project.name,
          stepNumber: step?.number,
        })}
      </PageHeader>

      <ProjectMenu project={project} />

      <Row>
        <MediaQuery maxWidth={SM_MAX_SIZE}>
          <Col xs={12}>
            <ProjectStatus project={project} />
          </Col>
        </MediaQuery>

        <Col xs={12} md={8}>
          {step && (
            <>
              <ProjectStepHeader
                completedSteps={project.completedSteps}
                project={project}
                step={step}
                userIsProjectOwner={userIsProjectOwner}
              />

              {step?.path === AppRoute.Step1 && (
                <ProjectDetailsPage
                  project={project}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step2 && (
                <AlternativeSupplyOutletsPage
                  stepData={steps[Steps.STEP_2]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step3 && (
                <AlternativeSupplyDuctsPage
                  stepData={steps[Steps.STEP_3]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step4 && (
                <ZoningSupplyDuctsPage
                  stepData={steps[Steps.STEP_4]}
                  step5Data={steps[Steps.STEP_5]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step5 && (
                <AutomaticZoningEquipmentPage
                  stepData={steps[Steps.STEP_5]}
                  step4Data={steps[Steps.STEP_4]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step6 && (
                <AlternativeReturnAirPage
                  stepData={steps[Steps.STEP_6]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step7 && (
                <DuctSealingPage
                  stepData={steps[Steps.STEP_7]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step8 && (
                <DetermineHeatLostGainPage
                  stepData={steps[Steps.STEP_8]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step9 && (
                <SizingCoolingCapacityPage
                  stepData={steps[Steps.STEP_9]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step10 && (
                <DehumidificationStrategyPage
                  stepData={steps[Steps.STEP_10]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step11 && (
                <SizingOfHeatingEquipmentPage
                  stepData={steps[Steps.STEP_11]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step12 && (
                <StagingModulatingHeatingPage
                  stepData={steps[Steps.STEP_12]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              {step?.path === AppRoute.Step13 && (
                <SpaceAndWaterHeatingPage
                  stepData={steps[Steps.STEP_13]}
                  updateProject={updateProjectFn}
                  userIsProjectOwner={userIsProjectOwner}
                />
              )}

              <ProjectStepFooter
                completedSteps={project.completedSteps}
                additionalInformation={project.additionalInformation}
                project={project}
                step={step}
                steps={steps}
                userIsProjectOwner={userIsProjectOwner}
                note={
                  step?.path === AppRoute.Step11 && (
                    <Disclaimer>
                      {t('step-sizingHeatingEquipment:note')}
                    </Disclaimer>
                  )
                }
              />
            </>
          )}
        </Col>
        <Col xs={12} md={4}>
          <MediaQuery minWidth={MD_MIN_SIZE}>
            <ProjectStatus project={project} />
          </MediaQuery>
          <ProjectComments
            project={project}
            pageId={Steps[`STEP_${step?.number}`]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ProjectStepsPage;
