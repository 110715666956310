import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { Button, PageHeader } from 'react-bootstrap';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Project, ProjectSituationSelections } from '../../model/Project';
import { ServerFeedback } from '../common/Form';
import { AppRoute } from '../../const';
import { LocalizedNavLink } from '../i18n';
import { ProjectDetailsForm } from '../Project';
import { defaultSituations } from '../../const/Projects';
import { Steps } from '../../model/Step';
import { partialUpdateProject } from '../../api/projects';
import { JsonPatchOperation } from '../../model/JsonPatchOperation';
import { CreateProjectProgress } from '.';

type FormValues = {
  situations: ProjectSituationSelections;
};

interface Props {
  project: Project;
}

const ProjectDetails: React.FC<Props> = ({ project }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [serverError, setServerError] = useState<string | undefined>();

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: { situations: project.situations || defaultSituations },
    mode: 'onChange',
  });

  const updateMutation = useMutation({
    mutationFn: (changes: JsonPatchOperation[]) =>
      partialUpdateProject(project.id, changes),
  });

  const onSubmit = (values: FormValues) => {
    const completedSteps = project.completedSteps;
    if (completedSteps.indexOf(Steps.STEP_1) === -1) {
      completedSteps.unshift(Steps.STEP_1);
    }

    updateMutation.mutate(
      [
        { op: 'add', path: '/updatedAt', value: new Date() },
        { op: 'add', path: '/situations', value: values.situations },
        { op: 'add', path: '/isStarted', value: true },
        { op: 'add', path: '/completedSteps', value: completedSteps },
      ],
      {
        onError: (err) => {
          console.log(err);
          setServerError(t('errors.common.unknown'));
        },
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey: ['projects'] });
          history.push(`/${t(AppRoute.Project)}/${project.id}`);
        },
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {t('pageTitle.createProject.title')} -{' '}
          {t('pageTitle.createProject.details')} - {t('pageTitle.appName')}
        </title>
      </Helmet>
      <PageHeader>
        {t('createProject.title')}
        {project && ` - ${project.name}`}
      </PageHeader>
      <CreateProjectProgress project={project} currentStep={3} />
      <h2>{t('createProject.details.title')}</h2>

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <ProjectDetailsForm control={control} userIsProjectOwner={true} />

        <div className="form-actions">
          {!!serverError && (
            <ServerFeedback type="error">{t(serverError)}</ServerFeedback>
          )}
          <Button
            type="submit"
            disabled={updateMutation.isPending}
            aria-disabled={updateMutation.isPending}
            bsStyle="primary"
          >
            {t('project.saveStartButton.label')}
          </Button>
          <LocalizedNavLink
            className="btn btn-default btn-sm"
            to={AppRoute.Dashboard}
          >
            {t('common.cancel')}
          </LocalizedNavLink>
        </div>
      </form>
    </>
  );
};

export default ProjectDetails;
